import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <footer className="bg-white text-black ">
       <div className="max-w-screen-xl mx-auto px-4 py-4">
        <hr className="my-4 border-black" />
        <p className="text-center">© {new Date().getFullYear()} Pas De Quoi</p>
      </div>
    </footer>
  );
};

export default Footer;
