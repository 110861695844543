import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import logostory from "../media/logoStory.jpg";
import logo2 from "../media/pasdequoilogo.jpg";
import pin from "../media/location.png";
const Home = () => {
  return (
    <section className="bg-white h-full flex flex-col justify-center items-center">
      <img src={logo2} alt="Logo" className="h-60 w-auto mb-8 mt-14" />
      <h1 className="text-6xl lg:text-8xl font-bold text-black mb-4 font-celebrate">
        Pas De Quoi
      </h1>
      <div className="flex flex-col items-center space-y-4">
        <div className="flex space-x-4">
          <a
            href="https://wa.me/"
            target="_blank"
            className="flex items-center justify-center py-2 px-4 bg-green-500 hover:bg-green-600 text-white rounded-lg text-lg font-medium transition duration-300 ease-in-out"
          >
            <FontAwesomeIcon icon={faWhatsapp} className="h-6 w-6 mr-2" />
            Contact
          </a>
          <a
            href="URL_HERE"
            target="_blank"
            className="flex items-center justify-center py-2 px-4 bg-gray-300 hover:bg-gray-400 text-gray-900 rounded-lg text-lg font-medium border border-gray-400 transition duration-300 ease-in-out"
          >
            <img src={pin} alt="Logo" className="h-6 w-auto mx-2" />
            Visit Us
          </a>
        </div>
        <div className="m-4">
          <a
            href="https://www.facebook.com/people/Pas-de-Quoi/61558057975110/"
            target="_blank"
            className="flex items-center justify-center my-2 bg-blue-600 rounded-lg py-2 px-4 hover:bg-blue-700 transition duration-300 ease-in-out"
          >
            <FontAwesomeIcon
              icon={faFacebook}
              className="text-white text-2xl mr-2"
            />
            <p className="text-white">Facebook</p>
          </a>

          <a
            href="https://www.instagram.com/Pas.de_quoi/"
            target="_blank"
            className="flex items-center justify-center my-2 bg-orange-600 rounded-lg py-2 px-4 hover:bg-orange-700 transition duration-300 ease-in-out"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              className="text-white text-2xl mr-2"
            />{" "}
            <p className="text-white">Instagram</p>
          </a>
          <a
            href="URL_HERE"
            target="_blank"
            className="flex items-center justify-center my-2 bg-blue-900 rounded-lg py-2 px-4 hover:bg-blue-950 transition duration-300 ease-in-out"
          >
            <FontAwesomeIcon
              icon={faTiktok}
              className="text-white text-2xl mr-2"
            />{" "}
            <p className="text-white">TikTok</p>
          </a>
        </div>
      </div>
      <div className="px-2 py-8">
        <h1 className=" mt-10 text-6xl lg:text-8xl  text-black mb-4 font-celebrate">
          Our Logo's Story
        </h1>
        <img src={logostory} alt="Logo" className="h-auto w-auto" />
      </div>
    </section>
  );
};

export default Home;
